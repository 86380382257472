<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar
      title="二手车质保车列表"
      left-arrow
      v-if="showDom == false"
      :fixed="true"
      :z-index="999"
      @click-left="back"
      @click-right="onClickRight"
    >
      <template #right>
        <van-icon v-if="usedCar !=2" name="plus" size="18" />
      </template>
    </van-nav-bar>
    <div class="hset" v-if="showDom == false">
      <div class="hes_a">销售商名称：</div>
      <div class="hes_b" style="text-align: right" @click="toxuanzhe">
        <span style="vertical-align: middle">{{sellCompany}}</span>
        <van-icon v-if="usedCar ==2" style="vertical-align: middle" name="arrow-down" />
      </div>
    </div>
    <div style="height: 100%;" v-if="showDom == false" class="tophes">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoads"
        :offset="10"
      >
        <div class="mian" @click="xiangqing(item)" v-for="(item,idx) in list" :key="idx">
          <div class="mian_a">
            <div class="let_a">
              <span class="lefs">车牌号：</span>
              <span>{{item.carNo}}</span>
            </div>
            <div class="let_b">
              <span v-if="item.status == 0" style="color: red">待顾客扫码注册</span>
              <span v-if="item.status == 1" style="color: red">待车辆质检</span>
              <span v-if="item.status == 2" style="color: red">待签约</span>
              <span v-if="item.status == 3" style="color: #07c160">已完成</span>
            </div>
          </div>
          <div class="mian_a">
            <div class="let_a">
              <div class="bord">
                <span class="lefs">车型：</span>
                <span>{{item.standard}}</span>
              </div>
              <div class="bord">
                <span class="lefs">客户：</span>
                <span>{{item.ownerName}}</span>
              </div>
              <div style="overflow: hidden;">
                <span class="lefs">提交时间：</span>
                <span>{{item.createdDate}}</span>
              </div>
            </div>
            <div class="let_b ma2" @click.stop="toerweima(item.qrCode)" v-if="item.status == 0">
              <vue-qr
                :text="item.qrCode"
                v-if="item.qrCode"
                :margin="0"
                colorDark="#000"
                colorLight="#fff"
                :dotScale="1"
                :logoScale="0.2"
                :size="130"
              ></vue-qr>
            </div>
            <div class="let_b" v-if="item.status == 2||item.status == 3">
              <van-button
                class="botss"
                v-if="item.sellSign == null"
                plain
                type="danger"
                size="small"
                @click.stop="qianming(item)"
              >去签约</van-button>
              <van-button class="botss" v-else plain type="primary" @click.stop="toheyue(item)" size="small">已签约</van-button>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
    <van-popup class="tanchuan" v-model="show">
      <vue-qr
        :text="qrCode"
        :margin="0"
        colorDark="#000"
        colorLight="#fff"
        :dotScale="1"
        :logoScale="0.2"
        :size="240"
      ></vue-qr>
    </van-popup>

    <div class="qianming" v-if="showDom == true">
      <div style="border: 2px solid #E6E6E6 ;background-color: white;w-100 h-100">
        <vue-esign
          ref="esign"
          :height="height"
          class="w-100 h-100"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
      </div>

      <van-icon class="shanchuico" name="close" @click.stop="showDom = false" />
      <div
        v-if="showDom"
        class="abs w-100 z-999 p-15"
        style="transform: rotate(90deg);top: 50%;left: -35%;"
      >
        <div class="w-100 m-t-10 f">
          <van-button class="m-5" block type="primary" size="large" @click="handleGenerate()">同意</van-button>
          <van-button class="m-5" block type="default" size="large" @click="handleReset">重新输入</van-button>
        </div>
      </div>
    </div>
    <van-popup v-model="showtan" position="bottom" :style="{ height: '45%' }">
      <!-- <div class="xslist" v-for="(item,idxs) in xslist" :key="idxs">{{item.sellCompany}}</div> -->
      <van-picker
        title="切换销售商门店"
        show-toolbar
        value-key="sellCompany"
        :columns="xslist"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import { carpage, carsign, selllist } from "../../api/check.js";

// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
import vueQr from "vue-qr";
export default {
  components: { vueQr },
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      cid: this.$route.query.cid,
      sellCompany: this.$route.query.sellCompany,
      usedCar: this.$route.query.usedCar,
      xslist: [], //销售商列表
      actv: 1,
      list: [],
      showtan: false,
      height: "",
      search: "",
      page: 0,
      size: 10,
      citem:{},
      loading: false,
      show: false,
      total: 0,
      finished: false,
      qrCode: "",
      id: "",
      isWxMini: "",
      //--------------
      imgs: null,
      imgss: null,
      showDom: false,
      baseData: "",
      // --------------
      lineWidth: 11,
      lineColor: "#000000",
      bgColor: "",

      resultImg: "",
      isCrop: false
    };
  },
  methods: {
    onConfirm(value, index) {
      this.cid = value.cid;
      this.sellCompany = value.sellCompany;
      this.page = 0;
      this.list = [];
      this.onLoad();
      this.showtan = false;
    },
    onCancel() {
      this.showtan = false;
    },
    //跳转合约
    toheyue(item){
       this.$router.push({
        path: "contract",
        query: {
          id: item.id,
          uesisok:'true'
        }
      });
    },
    //切换销售商门店
    toxuanzhe() {
      this.showtan = true;
    },
    qianming(item) {
      this.id = item.id;
      this.citem = item
      this.showDom = true;
    },
    handleReset() {
      console.log(this.$refs.esign);
      this.$refs.esign.reset();
    },
    //点击确定
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then(res => {
          console.log(res);
          this.baseData = res;
          this.submit();
        })
        .catch(err => {
          //   this.$toast("请签名后再生成签字图片");
        });
    },
    submit() {
      this.toast = this.$toast({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: "签名生成中..."
      });

      let _this = this;
      // var canvas = $(".jSignature");
      // var dataURL = canvas.get(0).toDataURL();
      this.rotateBase64Img(this.baseData, 270);
      setTimeout(() => {
        this.imgss = this.baseData;
        _this.toast.message = "签名上传中...";
        console.log(this.baseData);
        // var file = _this.dataURLtoFile(_this.baseData, "img");

        var file = _this.dataURLtoFile(_this.baseData, ".png");

        _this.updata(file);
      }, 300);
    },
    updata(file) {
      let _this = this;
      var formData = new FormData();
      formData.append("file", file);
      userModel.uploadPicturedan(formData).then(e => {
        _this.toast.clear();
        this.shanchuangtupian(e.data);
      });
    },
    //保存合同签名图
    shanchuangtupian(url) {
      var data = {
        id: this.id,
        type: 0,
        sign: url,
        sealPic:this.citem.sealPic,
        contractPic:this.citem.contractPic,
      };
      carsign(data).then(e => {
        // _this.$toast("请先完成签名！");
        if (e.code == 200) {
          this.$toast("签名成功！");
          this.list = [];
          this.page = 0;
          this.onLoad();
          this.showDom = false;
        }
      });
    },
    //				旋转base64
    rotateBase64Img(src, edg) {
      let _this = this;
      var edgData = "";
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw "旋转角度必须是90的倍数!";
      }
      edg < 0 && (edg = (edg % 360) + 360);
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      image.onload = function() {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx.translate(size, size);
        ctx.rotate((edg * Math.PI) / 180);
        ctx.drawImage(image, 0, 0);
        var imgData = ctx.getImageData(
          cutCoor.sx,
          cutCoor.sy,
          cutCoor.ex,
          cutCoor.ey
        );
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        _this.baseData = canvas.toDataURL();
      };
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    // ---------------

    //
    xiangqing(item) {
      this.$router.push({
        path: "Add_car",
        query: {
          names: "修改",
          id: item.id,
          usedCar:this.usedCar
        }
      });
    },
    onClickRight() {
      this.$router.push({
        path: "Add_car",
        query: {
          cid: this.cid,
          sellCompany: this.sellCompany
        }
      });
    },
    toerweima(qrcded) {
      if (qrcded) {
        this.qrCode = qrcded;
        this.show = true;
      }
    },
    tab(e) {
      this.actv = e;
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        cid: this.cid,
        page: this.page,
        size: this.size
        // uid: 1661
      };
      carpage(data)
        .then(e => {
          console.log(e);
          loading.clear();
          let rows = e.data.content;
          this.loading = false;
          this.total = e.data.totalElements;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      // this.page++;
      this.onLoad();
    },

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    if (this.$route.query.usedCar == 2) {
      selllist().then(e => {
        if (e.code == 200) {
          this.cid = e.data[0].cid;
          this.sellCompany = e.data[0].sellCompany;
          this.xslist = e.data;
          this.onLoad();
        }
      });
    } else {
      this.onLoad();
    }

    this.height = window.innerHeight;
    console.log(this.height);
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.hset {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 46px;
  overflow: hidden;
  padding: 0 12px;
  border-bottom: 2px solid #f5f5f5;
  background: #fff;
  z-index: 9;
}
.hes_a {
  width: 40%;
  line-height: 30px;
  font-size: 13px;
  float: left;
  color: #000;
}
.hes_b {
  width: 60%;
  line-height: 30px;
  font-size: 13px;
  float: left;
  color: #000;
}
.tophes {
  padding-top: 80px;
}
.mian {
  overflow: hidden;
  font-size: 13px;
  padding: 0 12px;
  border-bottom: 2px solid #f1f1f1;
}
.mian_a {
  line-height: 40px;
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1;
}
.bord {
  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
}
.let_a {
  width: 60%;
  float: left;
  overflow: hidden;
}
.let_b {
  width: 40%;
  float: left;
  text-align: right;
  overflow: hidden;
  position: relative;
}
.lefs {
  float: left;
  width: 74px;
}
.ma2 {
  position: relative;
}
.ma2 img {
  width: 100px !important;
  height: 100px !important;
  display: block !important;
  margin-top: 10px;
  float: right;
}
.botss {
  margin-top: 88px;
  width: 70px;
  height: 28px;
}
.tanchuan {
  padding: 20px;

  // width: 70%;
}
.shanchuico {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 36px;
  color: #666;
}

</style>